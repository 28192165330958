'use client';

import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { useAuth } from '~/app/hooks/AuthProvider';

import { Spinner } from '../common';

const ProtectedRoute = ({ children }: any) => {
  const { user } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (!user) router.push('/signin');
  }, [user]);

  if (!user) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white">
        <Spinner size={40} />
      </div>
    );
  }
  return <>{children}</>;
};

export default ProtectedRoute;
